import React from "react";
import '../../components/Layout/layout.css';

const h2 = ({ children }) => {
  return (
    <h2 className="h1">{children}</h2>
  );
};

export default h2;
